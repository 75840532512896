
  import { bus } from "@/main";
  import { Action, Getter } from "vuex-class";
  import { Vue, Component, Mixins, Watch } from "vue-property-decorator";
  import { FormBase, FormError, FormInput, FormSelect, FormDate, FormSubmit, FormTextarea, FormCheckbox, FormYesNo } from "@/components/forms";
  import { formatErrors } from "@/utils/formatters";
  import { HasVgeModal } from "@/mixins/vge/has-vge-modal";
  import { ImmoSignType, Partner, PartnerType } from "@/store/modules/partner.store";
  import { ImmoSignLayout } from "@/store/modules/vastgoedexperts.store";
  import { HasVgeOptions } from "@/mixins/vge/has-options";
  import { DocumentType } from "@/store/modules/document.store";
  import { HasFormErrors } from "@/mixins/has-form-errors";
  import type { VastgoedexpertsProduct } from "@/store/modules/vastgoedexperts.store";

  @Component({
    components: {
      LayoutModal: () => import("@/layouts/components/modal.vue"),
      FormBase,
      FormInput,
      FormDate,
      FormTextarea,
      FormSelect,
      FormError,
      FormCheckbox,
      FormYesNo,
      FormSubmit,
    },
  })
  export default class UpdateVgeSignsModal extends Mixins(HasFormErrors) {
    @Getter("activity/viewing") activity!: Activity;

    @Action("vastgoedexperts/updateImmoSigns") updateImmoSigns!: (payload: any) => Promise<void>;
    @Action("vastgoedexperts/readImmoSignLayouts") readImmoSignLayouts!: (activity_id: number) => Promise<ImmoSignLayout[]>;

    ImmoSignType = ImmoSignType;

    modalType = "show-vge-update-signs";

    documents = [DocumentType.vgeImmoSignPhotos, DocumentType.vgeImmoSignDocumentationExtra, DocumentType.vgeImmoSignDesignFiles];

    layoutOptions: any = [];

    show = false;

    product: VastgoedexpertsProduct | null = null;

    callback?: (product: VastgoedexpertsProduct) => void = undefined;

    asap = false;

    subActionOptions: any = [
      { value: 0, label: "Verkocht" },
      { value: 1, label: "Verhuurd" },
      { value: 2, label: "In optie" },
      { value: 3, label: "Aanpassing" },
      { value: 4, label: "Herstelling" },
      { value: 5, label: "Demontage" },
    ];

    adjustmentTypeOptions: any = [
      { value: 0, label: "Constructie/stelling verplaatsen" },
      { value: 1, label: "Verkocht/verhuurd/in optie terug verwijderen" },
      { value: 2, label: "Extra opdruk toevoegen/vervangen/plaatsen" },
    ];

    repairTypeOptions: any = [
      { value: 0, label: "Bord(en) vervangen/bord(en) beschadigd" },
      { value: 1, label: "Constructie/stelling beschadigd" },
    ];

    payload: any = {
      product_id: null,
      preferred_date: null,
      sub_action: null,
      type_adjustment: null,
    };

    get remarkPlaceholder() {
      if (!this.payload.sub_action) {
        return "Opmerkingen";
      }

      switch (this.payload.sub_action) {
        case 0:
        case 1:
        case 2:
        default:
          return "Opmerkingen";
        case 3:
          return "Gelieve hier duidelijk mee te geven welke informatie VastgoedExperts nodig heeft om de aanpassing zo correct mogelijk uit te voeren.";
        case 4:
          return "Gelieve hier duidelijk mee te geven welke informatie VastgoedExperts nodig heeft om de herstelling zo correct mogelijk uit te voeren.";
      }
    }

    mounted() {
      bus.$off(this.modalType);
      bus.$on(this.modalType, async (product: VastgoedexpertsProduct, callback?: (product: VastgoedexpertsProduct) => void) => {
        let promises = [];

        this.show = true;

        this.product = Object.assign({}, product);

        this.payload.product_id = product.id;

        this.callback = callback;

        this.handleAfterOpen();
      });
    }

    handleAfterOpen() {
      this.readImmoSignLayouts(this.activity.id).then((layouts: ImmoSignLayout[]) => {
        this.layoutOptions = layouts.map((layout: ImmoSignLayout) => {
          const nameSuffix = layout.opdruk && layout.formaat ? `- ${layout.opdruk} - ${layout.formaat}` : "";
          const stock: any = layout.hasOwnProperty("stock") ? ` - (${layout.stock} stuks)` : "";

          return {
            ...layout,
            value: layout.layout_id,
            label: `${layout.name} ${nameSuffix} ${stock}`,
          };
        });
      });
    }

    async submit(form: FormClass) {
      try {
        await this.updateImmoSigns(this.payload);

        this.handleClose();

        if (this.callback && this.product) {
          this.callback(this.product);
        }

        this.$toast.open({ message: "Aanpassing aangevraagd", type: "success" });
      } catch (e) {
        this.errorResponse = formatErrors(e);
      } finally {
        form.reset();
      }
    }

    handleClose() {
      this.clearErrors();

      this.show = false;
    }

    @Watch("asap")
    handleAsapChange(newValue: boolean) {
      if (newValue) {
        this.payload.preferred_date = null;
      }
    }
  }
